import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

// Styled Components
const Container = styled.div`
  // background-color: #f9fafb;
  padding: 40px 20px;
`;

const ContentWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Heading = styled.h2`
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
`;

const AccordionWrapper = styled.div``;

const AccordionItem = styled.div`
  margin-bottom: 20px;
`;

const AccordionHeading = styled.div`
  background-color: #ebecf0;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
`;

const AccordionPanel = styled.div`
  max-height: ${(props) => (props.isOpen ? "200px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease;
  background-color: white;
  padding: ${(props) => (props.isOpen ? "20px" : "0 20px")};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
`;

const IconWrapper = styled.div`
  margin-left: 10px;
`;

const AccordionIcon = styled.svg`
  width: 44px;
  height: 44px;
  stroke: #acaebf;
`;

const Accordion = ({ title, content, isOpen, onClick }) => (
  <AccordionItem>
    <AccordionHeading onClick={onClick}>
      {title}
      <IconWrapper>
        <AccordionIcon viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M34.2224 21.9997H9.77765" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          {isOpen ? (
            <path d="M22 9.77734V34.2221" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          ) : null}
        </AccordionIcon>
      </IconWrapper>
    </AccordionHeading>
    <AccordionPanel isOpen={isOpen}>
      <p>{content}</p>
    </AccordionPanel>
  </AccordionItem>
);

const DonationDetails = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Container>
      <ContentWrapper>
        <Heading>Donation Details</Heading>
        <AccordionWrapper>
          <Accordion
            title="Are award contributions tax deductible?"
            content="Yes! Donations are considered as a 501(c)(3) non-profit (Federal Tax ID #84-2719715). When your donation is processed, you’ll receive an email with documentation confirming your tax-deductible donation."
            isOpen={openIndex === 0}
            onClick={() => toggleAccordion(0)}
          />
           <Accordion
            title="How long does it take for donations to process?"
            content="Donations made by credit card process right away. Donations made by ACH can take 3-5 business days to process."
            isOpen={openIndex === 1}
            onClick={() => toggleAccordion(1)}
          />
           <Accordion
            title="How do I know how my donation will be used?"
            content="100% of your tax-deductible donation will go to a deserving recipient chosen based on the selection criteria for your scholarship, and will typically be either applied directly to their tuition or directly to their student loans. In cases where you’ve requested the award go towards an educational experience, we require documentation from the recipient that the award has been used for eligible expenses."
            isOpen={openIndex === 2}
            onClick={() => toggleAccordion(2)}
          />
           <Accordion
            title="What are the possible causes to which awards can go?"
            content="In general, we work with financial aid offices to apply awards directly to your recipient’s tuition. If you’d like, you can also specify that you’d like your award to go towards paying off your recipient’s student loans, or to covering other educational expenses. In all cases, the purpose of your grant must be to achieve a specific objective, produce a report or other similar product, or improve or enhance a literary, artistic, musical, scientific, teaching, or other similar capacity, skill, or talent of the grantee."
            isOpen={openIndex === 3}
            onClick={() => toggleAccordion(3)}
          />
           <Accordion
            title="Can reviewers have unlimited logins, or is there a limit?"
            content="We currently support one login per donor account, but are working on multi-login support."
            isOpen={openIndex === 4}
            onClick={() => toggleAccordion(4)}
          />
           <Accordion
            title="Can I interview candidates or request letters of recommendation from them?"
            content="Yes, we can typically facilitate this upon request!"
            isOpen={openIndex === 5}
            onClick={() => toggleAccordion(5)}
          />
            <Accordion
            title="What types of donors are on funding success?"
            content="Funding success donors include individuals, families, large and small companies, foundations, and more. We make it easy for anyone and any organization to create and manage customized scholarships, fellowships, and grants, in minutes."
            isOpen={openIndex === 6}
            onClick={() => toggleAccordion(6)}
          />
           <Accordion
            title="How do you make money?"
            content="We work with highly vetted partner companies that support our operations and allow us to keep Bold.org completely free for applicants and fee-free for donors, with 100% of donations going to recipients."
            isOpen={openIndex === 7}
            onClick={() => toggleAccordion(7)}
          />
          {/* Add more Accordion items as needed */}
        </AccordionWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default DonationDetails;

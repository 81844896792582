import React from 'react';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';
import Coin from '../assets/images/money-coin.webp';
import { useNavigate } from 'react-router-dom';

// Styled Components
const ContentWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Container = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 40px 0;
  position: relative;
  z-index: 10;
`;

const Heading = styled.h1`
  margin-top: 8px;
  margin-bottom: 12px;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 900;
  color: #333;
  @media (min-width: 768px) {
    font-size: 3.5rem;
  }
`;

const SubHeading = styled.p`
  margin: 0 auto 24px;
  text-align: center;
  font-size: 1.125rem;
  color: #555;
  max-width: 720px;
  line-height: 1.6;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ScholarshipContainer = styled.div`
  margin: 24px 0;
  padding: 24px;
  background: white;
  max-width: 580px;
  border-radius: 24px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
`;

const InfoText = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  border-radius: 16px;
  @media (min-width: 768px) {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #003366;
  margin: 0 12px;
`;

const FeatureIcon = styled(FaCheck)`
  margin-right: 16px;
  color: white;
  background-color: #003366;
  border-radius: 50%;
  padding: 8px;
  width: 24px;
  height: 24px;
`;

const FeatureText = styled.div`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.25rem;
`;

const Button = styled.a`
  display: inline-block;
  background-color: #003366;
  color: white;
  padding: 12px 24px;
  border-radius: 24px;
  text-align: center;
  font-size: 1.25rem;
  margin-top: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;

  &:hover {
    background-color: #003369;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  min-height: 450px;
  margin-top: 48px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const FooterTextContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  text-align: center;
`;

const FooterHeading = styled.h2`
  font-size: 1.75rem;
  font-weight: 900;
  padding-bottom: 16px;
  color: #333;
  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const FooterParagraph = styled.p`
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

const MaximizeImpact = () => {
  const navigate = useNavigate();  // Correct placement of useNavigate

  return (
    <ContentWrapper>
      <Container>
        <div>
          <Heading>Maximize your impact</Heading>
          <SubHeading>
            100% of your donation will go directly to the educational costs of the
            winners you select.
          </SubHeading>
        </div>
        <ScholarshipContainer>
          <InfoText>Free for applicants, free for you</InfoText>
          <Feature>
            <FeatureIcon />
            <FeatureText>Polished scholarship page to share broadly</FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon />
            <FeatureText>1-on-1 support from our team at every step</FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon />
            <FeatureText>A list of finalists based on your scholarship criteria</FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon />
            <FeatureText>Interviews with finalists, organized by Funding Success</FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon />
            <FeatureText>Featured communications to relevant schools</FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon />
            <FeatureText>Recruiting top Funding Success candidates</FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon />
            <FeatureText>Easy way to accept any and all 3rd party donations</FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon />
            <FeatureText>Promotion through high-engagement social channels</FeatureText>
          </Feature>
          <div style={{ textAlign: 'center', marginTop: '1rem' }}>
            <Button onClick={() => navigate('/login')}>Create a scholarship now</Button>
          </div>
        </ScholarshipContainer>
        <ImageWrapper>
          <Image alt="Graphics" src={Coin} />
          <FooterTextContainer>
            <FooterHeading>We work hard to keep Funding Success almost free for everyone.</FooterHeading>
            <FooterParagraph>
              Funding Success is completely free for students and 100% free for donors. To do this,
              we match students with helpful, vetted, optional resources to get them
              off to a great start in their education and career.
            </FooterParagraph>
            <FooterParagraph>
              When students find those resources useful, we earn revenue from partner
              companies, allowing 100% of your donation to go to the educational
              expenses of applicants you select.
            </FooterParagraph>
          </FooterTextContainer>
        </ImageWrapper>
      </Container>
    </ContentWrapper>
  );
};

export default MaximizeImpact;

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import FundingSuccessLogoSVG from '../component/SVG/FundingSuccessLogoSVG';
import { FaChevronDown, FaRegStar, FaUserShield, FaDollarSign } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';


// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  overflow-y: scroll;
  padding: 20px;
  background-color: white;

  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;


const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 4em;
  font-weight: bold;
  color: #03377e;
  margin: 0;
`;

const Subtitle = styled.p`
  font-size: 1.5em;
  color: #577483;
  margin-bottom: 50px;

  span {
    color: #03377e;
    font-weight: bold;
  }
`;

const Section = styled.div`
  text-align: center;
  max-width: 800px;
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 2em;
  color: #03377e;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 1.2em;
  color: #577483;
  line-height: 1.5;
`;

const Button = styled(motion.button)`
  padding: 10px 20px;
  font-size: 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #03377e;
  color: #ffffff;
  margin-top: 30px;

  &:hover {
    background-color: #022a5f;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background-color: white;
  font-size: 1em;
  color: #577483;
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;

  svg {
    font-size: 2em;
    margin-bottom: 10px;
  }
`;

const StyledLogo = styled(FundingSuccessLogoSVG)`
  height: 30px; 
  position: absolute;
  left: 20px;
  top: 20px;

  @media (max-width: 768px) {
    height: 25px; /* Smaller logo for mobile */
    left: 10px;   /* Adjust left position for mobile */
    top: 10px;    /* Adjust top position for mobile */
  }

  @media (max-width: 480px) {
    height: 20px; /* Even smaller logo for very small screens */
    left: 8px;
    top: 8px;
  }
`;


const AboutUsPage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <StyledLogo onClick={() => navigate('/')}/>
      <TitleContainer>
        <Title>About Us</Title>
      </TitleContainer>

      <Section>
        <SectionTitle>Our Mission</SectionTitle>
        <Paragraph>
          <span>Funding for Success</span> is an innovative platform that bridges the gap between high-achieving students and visionary donors eager to invest in the leaders of tomorrow. By showcasing verified academic achievements, we give donors the confidence and privacy that their support is fueling committed and exceptional talent, creating lasting impact for both students and society.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>The Problem We Solve</SectionTitle>
        <Paragraph>
          Many successful students carry significant loan balances even after completing their degrees, making financial planning post-graduation a challenge. With about 37% of students at public institutions carrying student loans, there is a need for an alternative funding source to ease the burden. Our platform connects high-achieving students with donors who want to invest in their future, ensuring transparency and trust throughout the process.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>Our Solution</SectionTitle>
        <Paragraph>
          Our platform offers a verified and transparent connection between students and philanthropists. By validating student credentials through higher education institutions and providing financial transparency, we build trust between donors and students. We also provide an opportunity for financial institutions to engage with students by offering financial services to those who need educational loans.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>Who We Serve</SectionTitle>
        <Paragraph>
          We serve students, philanthropists, universities, and financial institutions. Our platform benefits students by providing access to funding without relying solely on loans, universities by promoting academic excellence, philanthropists by ensuring their contributions make a lasting impact, and financial institutions by giving them direct access to students in need of educational loans.
        </Paragraph>
      </Section>

      <Button
        whileHover={{ scale: 1.1 }}
        onClick={() => navigate('/login')}
      >
        Get Involved
      </Button>

      <Footer>
        <Icon>
          <FaDollarSign /> {/* Icon for "100% free" */}
          <p>100% free</p>
        </Icon>
        <Icon>
          <FaUserShield /> {/* Icon for "Trusted by Donors and Students" */}
          <p>Trusted by Donors and Students</p>
        </Icon>
        <Icon>
          <FaRegStar /> {/* Icon for "5-star reviews" */}
          <p>5-star reviews</p>
        </Icon>
      </Footer>
    </Container>
  );
};

export default AboutUsPage;
